const state = {
    tempSelectGamesList:{},
    isShowMask:false,
    filterResultForGamesList:null,
   //  totalCount:0
 }
 const mutations = {
    //“我要买”菜单中的筛选的游戏列表
    setTempSelectGamesList(state,payload){
       state.tempSelectGamesList[payload.itemName]=payload.itemValue;
       //console.log('setTempSelectGamesList==='+JSON.stringify(state.tempSelectGamesList))
    },
    //重置单选、多选、输入框组件的记录
    resetTempSelectGamesList(state,payload){
      payload.forEach(element => {
         delete state.tempSelectGamesList[element.aliasTitle];
      });
      //console.log('resetTempSelectGamesList==='+JSON.stringify(state.tempSelectGamesList))
    },
    setIsShowMask(state,payload){
      // console.log('payload=='+payload)
      state.isShowMask = payload;
    },
    //根据筛选条件，获取游戏列表
    setFilterResultForGamesList(state,payload){
      //console.log('payload=='+JSON.stringify(payload))
      state.filterResultForGamesList = payload;
    }, 
    //重置所有选择项
    resetAllSelectedItems(state,payload){
      state.tempSelectGamesList = {pageNumber:1,pageSize:20};
      //console.log('setTempSelectGamesList==='+JSON.stringify(state.tempSelectGamesList));
    }

 }
 
 const actions = {
    
 }
 
 const getters = {
 
 }
 
 export default {
    namespaced:true,
     state,
     mutations,
     actions,
     getters
 }