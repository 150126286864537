const state = {
    currentStepsIndex:1,
    currentSelectedGameItem:{}
 }
 const mutations = {
    // 发布商品时的当前步骤索引
    setCurrentStepsIndex(state,payload){
        window.scrollTo(0, 0);
        console.log('发布产品，当前步骤是：',payload)
        state.currentStepsIndex = payload;
    },
    setCurrentSelectedGameItem(state,payload){
      console.log('当前选择的游戏是：',payload)
      state.currentSelectedGameItem = payload;
    }

 }
 
 const actions = {
    
 }
 
 const getters = {
 
 }
 
 export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
 }