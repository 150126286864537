//vee-validate插件：表单验证区域’
import Vue from "vue";
import VeeValidate from "vee-validate";
//中文提示信息
import zh_CN from "vee-validate/dist/locale/zh_CN";
Vue.use(VeeValidate,{fieldsBagName: 'veefields'});

//表单验证
VeeValidate.Validator.localize("zh_CN", {
  messages: {   // 自定义错误信息提示 field 即为需要检验的属性，${field} 获取属性对应的值（attributes 中的值）
    ...zh_CN.messages,
    is: (field) => `${field}必须与密码相同`, // 修改内置规则的 message，让确认密码和密码相同
    regex: (field) => `${field}必须是大小写字母、数字组成，长度6-20位。`
  },
  attributes: {   // 需要检验的属性
    account:"用户名",
    mobilePhoneNumber: "手机号",
    code:"验证码",
    password: "密码",
    confirmPassword: "确认密码",
    agree:'协议'
  },
});

//自定义校验规则
VeeValidate.Validator.extend("checkPhone", {
  validate: (value) => {
    return /^1\d{10}$/.test(value);
  },
  getMessage: (field) => field + "格式错误",
});

VeeValidate.Validator.extend("checkCode", {
  validate: (value) => {
    return /^\d{6}$/.test(value);
  },
  getMessage: (field) =>"请输入6位数"+field,
});

VeeValidate.Validator.extend("checkPassword", {
  validate: (value) => {
    return /^[0-9A-Za-z]{6,20}$/.test(value);
  },
  getMessage:(field) => field + "必须是大小写字母、数字组成，长度6-20位。",
});
