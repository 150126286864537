import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './common/index'



Vue.use(VueRouter)

const router = new VueRouter({
  mode:'hash',
  routes,
  linkActiveClass: 'active', //菜单被激活时的样式
})

export default router

router.beforeEach((to, from, next) => {
  // 在路由切换前保存当前滚动位置
  const currentPosition = window.scrollY || document.documentElement.scrollTop;
  sessionStorage.setItem('scrollPosition', currentPosition);
  next();
});
 
router.afterEach(() => {
  // 在路由切换后恢复滚动位置
  window.scrollTo(0, sessionStorage.getItem('scrollPosition') || 0);
});