const state = {
    currentMenuIndex:0
 }
 const mutations = {
    //设置菜单当前索引
    setCurrentMenuIndex(state,payload){
        //console.log('当前菜单索引是：'+payload)
        state.currentMenuIndex = payload;
    }

 }
 
 const actions = {
    
 }
 
 const getters = {
 
 }
 
 export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
 }