import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import home from './home'
import buyList from './buyList'
import helpCenter from './helpCenter'
import user from './user'
import publishProduct from './publishProduct'


export default new Vuex.Store({
    modules:{
        home,
        buyList,
        helpCenter,
        user,
        publishProduct
    }
})