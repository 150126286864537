<template>
   <div class="container">
       <div class="header">
            <div class="logo-search">
                <div class="logo" @click="$router.push('/',()=>{})">
                    <img src="../../../public/images/home/logo.png"/>
                </div>
                <div class="search">
                    <input type="text" class="search-text" placeholder="输入游戏名称搜索" >
                    <div class="image-btn">
                        <img src="https://hwj-games-market.oss-cn-shanghai.aliyuncs.com/banner/header_sosuo_icon.png"
                    alt="" width="19px" height="20px">
                    </div>
                </div>
            </div>
            <div class="nav">
                <ul>
                    <li ref="menu_1" @click="clickMenuHandler(1)"><span>首页</span></li>
                    <li ref="menu_2" @click="clickMenuHandler(2)"><span>我要买</span></li>
                    <li ref="menu_3" @click="clickMenuHandler(3)"><span>中介担保</span></li>
                    <li ref="menu_4" @click="clickMenuHandler(4)"><span>业务介绍</span></li>
                    <li ref="menu_5" @click="clickMenuHandler(5)"><span>帮助中心</span></li>
                    <li ref="menu_6" @click="clickMenuHandler(6)"><span>游戏资讯</span></li>
                    <li ref="menu_7" @click="clickMenuHandler(7)"><span>个人中心</span></li>
                    <li ref="menu_8" @click="clickMenuHandler(8)"><span>下载App</span></li>
                    <div ref="slider" class="slider" :style="{left:sliderLeft}"></div>
                </ul>
            </div>
       </div>
   </div>
</template>

<script>
export default { 
    data(){
        return{
            ele_left:0,
            ele_width:0
        }
    },
    computed: {
        sliderLeft() {
            return this.$store.state.home.sliderLeft;
        }
    },
    methods:{
        clickMenuHandler(index){
            //滑块动画
            this.sliderChange(index);
            this.$store.commit('home/setCurrentGlobalMenuIndex',index)
            //跳转
            setTimeout(()=>{
                switch(index){
                    case 1:
                        this.$router.push('/',()=>{});
                        break;
                    case 2:
                        this.$router.push('/buyList',()=>{});
                        
                        break;
                    case 3:
                        this.$router.push('/guarantee',()=>{});
                        break;
                    case 4:
                        this.$router.push('/businessIntroduction',()=>{});
                        break;
                    case 5:
                        this.$router.push('/helpCenter',()=>{});
                        break;
                    case 6:
                        this.$router.push('/gamesNews',()=>{});
                        break;
                    case 7:
                        this.$router.push('/myCenter',()=>{});
                        break;
                    case 8:
                        this.$router.push('/downloadApp',()=>{});
                        break;
                    default:
                        break;
                }
            },300)
        },

        sliderChange(index){
            let item = 'menu_'+index;
            //console.log('item---'+item);
            //所选元素距离左边宽度
            this.ele_left = this.$refs[item].offsetLeft;
            //所选元素总宽度：this.$refs[item].offsetWidth
            this.ele_width = (Number.parseInt(this.$refs[item].offsetWidth)-30)/2;
            //this.$refs.slider.style.left = this.ele_left+this.ele_width+'px';
            this.$store.commit('home/updateSliderLeft', this.ele_left+this.ele_width+'px');
            //console.log(this.ele_left+this.ele_width);
        }
    }
}
</script>

<style lang="less" scoped>
/* 定义版心样式 */
.container{
    //width: 1920px;
    // width: calc(100vw);
    width: calc(100vw);
    // min-width: 1200px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 0px 0px #F02233;
    margin-bottom: 2px;
    .header {
        margin: 0 auto;
        width: 1200px;
        
        .logo-search{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FFFFFF;
            height: 106px;
            width: 1200px;
            .logo{
                width: 184px;
                height: 60px;
            }

            .search{
                display: flex;
                justify-content: space-between;
                width: 670px;
                height: 46px;
                border: 2px solid #F02233;
                background-color: #F02233;
                border-radius: 4px;
                .search-text{
                    width: 570px;
                    border: none;
                    padding: 0 10px;
                    border-radius: 4px 0 0 4px;
                    font-size: 16px;
                    font-family: MicrosoftYaHei;
                    outline: none;

                    &::placeholder{
                        color: #C3C3C3;
                        font-size: 16px;
                    }
                }
                .image-btn{
                    width: 100px;
                    height: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    image{
                        width: 20px;
                        height: 20px;
                        background: #F02233;
                    }
                }
            }

        }

        .nav{
            width: 1200px;
            height: 52px;
            line-height: 52px;
            background: #FFFFFF;
            ul{
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: nowrap;
                height: 52px;
                position: relative;
                list-style-type: none;
                padding: 0;
                li{
                    cursor: default;
                    span{
                        font-size: 16px;
                        color: #424242;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        height: 16px;
                        line-height: 16px;
                    }

                    &:hover span{
                        color: #F02233;
                    }
                }

                .slider{
                    width: 30px;
                    height: 6px;
                    position: absolute;
                    left: 47px;
                    bottom: 0;
                    background: #F02233;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}
</style>