const state = {
    isWithdrawMenu:false,
    userToken:'',
    baseUserInfo:{},
    myCenterMenuIndex:0
 }
 const mutations = {
    // 个人中心设置基本信息是否显示余额卡
    setIsWithdrawMenu(state,payload){
        //console.log('当前payload是：'+payload)
        state.isWithdrawMenu = payload;
    },
    //保存用户token和基本信息
    saveUserToken(state,payload){
      state.userToken = payload.token;
      state.baseUserInfo = payload;
      localStorage.setItem("userToken",JSON.stringify(payload.token))
   },
   //个人中心菜单
   setMyCenterMenuIndex(state,payload){
      state.myCenterMenuIndex = payload;
   }

 }
 
 const actions = {
    
 }
 
 const getters = {
 
 }
 
 export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
 }